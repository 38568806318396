.text_hover_effect {
	outline: none;
	text-decoration: none;
	position: relative;
	font-size: 8em;
	line-height: 1;
	color: #9e9ba4;
	display: inline-block;
}
.text_hover_effect::hover{
	color:#9e9ba4;
}

.link--kukuri {
	text-transform: uppercase;
	font-weight: 900;
	overflow: hidden;
	line-height: 0.75;
	color: #b2b0a9;
}

.link--kukuri.active {
	color: #b2b0a9;
}

.link--kukuri::after {
	content: '';
	position: absolute;
	height: 16px;
	width: 100%;
	top: 50%;
	margin-top: -8px;
	right: 1px;
	background: #F9F9F9;
	transform: translate3d(-100%,0,0);
	transition: transform 0.4s;
	transition-timing-function: cubic-bezier(0.7,0,0.3,1);
}

.link--kukuri.active::after {
	transform: translate3d(101%,0,0);
}

.link--kukuri::before {
	content: attr(data-letters);
	position: absolute;
	z-index: 2;
	overflow: hidden;
	color: #424242;
	white-space: nowrap;
	width: 0%;
	transition: width 0.4s 0.3s;
}

.link--kukuri.active::before {
	width: 100%;
}

/*dark version*/
.link--kukuri.dark::before {
	color:#818181;
}