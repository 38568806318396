.input {
	position: relative;
	z-index: 1;
	display: inline-block;
	margin: 1em;
	/*max-width: 400px;*/
	/*width: calc(100% - 2em);*/
	vertical-align: top;
}

.input__field {
	position: relative;
	display: block;
	float: right;
	padding: 0.8em;
	width: 60%;
	border: none;
	border-radius: 0;
	background: #f0f0f0;
	color: #aaa;
	font-weight: 400;
	font-family: "Avenir Next", "Helvetica Neue", Helvetica, Arial, sans-serif;
	-webkit-appearance: none; /* for box shadows to show on iOS */
}

.input__field:focus {
	outline: none;
}

.input__label {
	display: inline-block;
	float: right;
	padding: 0 1em;
	width: 40%;
	color: #6a7989;
	font-weight: bold;
	font-size: 70.25%;
	-webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.input__label-content {
	position: relative;
	display: block;
	padding: 1.6em 0;
	width: 100%;
}

.graphic {
	position: absolute;
	top: 0;
	left: 0;
	fill: none;
}

.icon {
	//color: #ddd;
	font-size: 150%;
}

/* Individual styles */

/* Nao */
.input--nao {
	overflow: hidden;
	padding-top: 1em;
}

.input__field--nao {
	padding: 0.5em 0em 0.25em;
	width: 100%;
	background: transparent;
	color: #9da8b2;
	font-size: 1.25em;
}

.input__label--nao {
	position: absolute;
	top: 0.95em;
	font-size: 0.85em;
	left: 0;
	display: block;
	width: 100%;
	text-align: left;
	padding: 0em;
	pointer-events: none;
	-webkit-transform-origin: 0 0;
	transform-origin: 0 0;
	-webkit-transition: -webkit-transform 0.3s 0.1s, color 1s;
	transition: transform 0.3s 0.1s, color 1s;
	-webkit-transition-timing-function: cubic-bezier(0, 0.25, 0.5, 1);
	transition-timing-function: cubic-bezier(0, 0.25, 0.5, 1);
}

.graphic--nao {
	stroke: #92989e;
	pointer-events: none;
	-webkit-transition: -webkit-transform 0.7s, stroke 0.7s;
	transition: transform 0.7s, stroke 0.7s;
	-webkit-transition-timing-function: cubic-bezier(0, 0.25, 0.5, 1);
	transition-timing-function: cubic-bezier(0, 0.25, 0.5, 1);
} 

.input__field--nao:focus + .input__label--nao,
.input--filled .input__label--nao {
	color: #333;
	-webkit-transform: translate3d(0, -1.25em, 0) scale3d(0.75, 0.75, 1);
	transform: translate3d(0, -1.25em, 0) scale3d(0.75, 0.75, 1);
}

.input__field--nao:focus ~ .graphic--nao,
.input--filled .graphic--nao {
	stroke: #333;
	-webkit-transform: translate3d(-66.6%, 0, 0);
	transform: translate3d(-66.6%, 0, 0);
}

/* Shoko */
.input--shoko {
	overflow: hidden;
	padding-bottom: 2.5em;
}

.input__field--shoko {
	padding: 0;
	margin-top: 1.2em;
	width: 100%;
	background: transparent;
	color: #fff;
	font-size: 1.55em;
}

.input__label--shoko {
	position: absolute;
	top: 2em;
	left: 0;
	display: block;
	width: 100%;
	text-align: left;
	padding: 0em;
	text-transform: uppercase;
	letter-spacing: 1px;
	color: #1586C7;
	pointer-events: none;
	-webkit-transform-origin: 0 0;
	transform-origin: 0 0;
	-webkit-transition: -webkit-transform 0.3s 0.1s, color 1s;
	transition: transform 0.3s 0.1s, color 1s;
	-webkit-transition-timing-function: cubic-bezier(0, 0.25, 0.5, 1);
	transition-timing-function: cubic-bezier(0, 0.25, 0.5, 1);
}

.graphic--shoko {
	stroke: #1586C7;
	pointer-events: none;
	stroke-width: 1px;
	top: 1.25em;
	bottom: 0px;
	height: 3.275em;
	-webkit-transition: -webkit-transform 0.7s, stroke 0.7s;
	transition: transform 0.7s, stroke 0.7s;
	-webkit-transition-timing-function: cubic-bezier(0, 0.25, 0.5, 1);
	transition-timing-function: cubic-bezier(0, 0.25, 0.5, 1);
} 

.input__field--shoko:focus + .input__label--shoko,
.input--filled .input__label--shoko {
	color: #0d689c;
	-webkit-transform: translate3d(0, 3.5em, 0) scale3d(0.85, 0.85, 1);
	transform: translate3d(0, 3.5em, 0) scale3d(0.85, 0.85, 1);
}

.input__field--shoko:focus ~ .graphic--shoko,
.input--filled .graphic--shoko {
	stroke: #fff;
	-webkit-transform: translate3d(-66.6%, 0, 0);
	transform: translate3d(-66.6%, 0, 0);
}

/* Yoshiko */
.input__field--yoshiko {
	width: 100%;
	background-color: #d0d1d0;
	border: 2px solid transparent;
	-webkit-transition: background-color 0.3s, border-color 0.3s;
	transition: background-color 0.3s, border-color 0.3s;
}

.input__label--yoshiko {
	width: 100%;
	text-align: left;
	position: absolute;
	bottom: 100%;
	pointer-events: none;
	overflow: hidden;
	padding: 0 1.25em;
	-webkit-transform: translate3d(0, 3em, 0);
	transform: translate3d(0, 3em, 0);
	-webkit-transition: -webkit-transform 0.3s;
	transition: transform 0.3s;
}

.input__label-content--yoshiko {
	color: #8B8C8B;
	padding: 0.25em 0;
	-webkit-transition: -webkit-transform 0.3s;
	transition: transform 0.3s;
}

.input__label-content--yoshiko::after {
	content: attr(data-content);
	position: absolute;
	font-weight: 800;
	bottom: 100%;
	left: 0;
	height: 100%;
	width: 100%;
	color: #a3d39c;
	padding: 0.25em 0;
	text-transform: uppercase;
	letter-spacing: 1px;
	font-size: 0.85em;
}

.input__field--yoshiko:focus + .input__label--yoshiko,
.input--filled .input__label--yoshiko {
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}

.input__field--yoshiko:focus + .input__label--yoshiko .input__label-content--yoshiko,
.input--filled .input__label-content--yoshiko {
	-webkit-transform: translate3d(0, 100%, 0);
	transform: translate3d(0, 100%, 0);
}

.input__field--yoshiko:focus + .input__field--yoshiko,
.input--filled .input__field--yoshiko {
	background-color: transparent;
	border-color: #a3d39c;
}

/* Chisato */

.input--chisato {
	padding-top: 1em;
}

.input__field--chisato {
	width: 100%;
	padding: 0.8em 0.5em;
	background: transparent;
	border: 2px solid;
	color: #b5b5b5;
	-webkit-transition: border-color 0.3s;
	transition: border-color 0.3s;
}

.input__label--chisato {
	width: 100%;
	position: absolute;
	top: 0;
	text-align: left;
	overflow: hidden;
	padding: 0;
	pointer-events: none;
	-webkit-transform: translate3d(0, 3em, 0);
	transform: translate3d(0, 3em, 0);
}

.input__label-content--chisato {
	padding: 0 1em;
	font-weight: 400;
	color: #b5b5b5;
}

.input__label-content--chisato::after {
	content: attr(data-content);
	position: absolute;
	top: -200%;
	left: 0;
	color: #da6484;
	font-weight: 800;
}

.input__field--chisato:focus,
.input--filled .input__field--chisato {
	border-color: #da6484;
}

.input__field--chisato:focus + .input__label--chisato,
.input--filled .input__label--chisato {
	-webkit-animation: anim-chisato-1 0.3s forwards;
	animation: anim-chisato-1 0.3s forwards;
}

.input__field--chisato:focus + .input__label--chisato .input__label-content--chisato,
.input--filled .input__label-content--chisato {
	-webkit-animation: anim-chisato-2 0.3s forwards;
	animation: anim-chisato-2 0.3s forwards;
}

@-webkit-keyframes anim-chisato-1 {
	0%, 50% {
		-webkit-transform: translate3d(0, 3em, 0);
		transform: translate3d(0, 3em, 0);
	}
	51%, 100% {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}

@-webkit-keyframes anim-chisato-2 {
	0% {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
	50%, 51% {
		-webkit-transform: translate3d(0, 100%, 0);
		transform: translate3d(0, 100%, 0);
	}
	100% {
		color: transparent;
		-webkit-transform: translate3d(0, 200%, 0);
		transform: translate3d(0, 200%, 0);
	}
}

/* Kozakura */
.input--kozakura {
	overflow: hidden;
	padding-bottom: 10px;
}

/*input field  before*/
.input__field--kozakura {
	padding: 5xp 10px;
	/*margin-top: 1.25em;*/
	margin-top: 32px;
	width: 100%;
	background: transparent;
	color: #fff;
	/*font-size: 1.55em;*/
	font-size: 20px;
	opacity: 0;
	height:55px;
}

.input__label--kozakura {
	width: 100%;
	text-align: left;
	position: absolute;
	/*top: 1em;*/
	top: -8px;
	pointer-events: none;
	overflow: hidden;
	padding: 0;
	/*-webkit-transform: translate3d(1em, 2.75em, 0);
	transform: translate3d(1em, 2.75em, 0);*/
	-webkit-transform: translate3d(10px, 42px, 0);
	transform: translate3d(10px, 42px, 0);
	-webkit-transition: -webkit-transform 0.3s;
	transition: transform 0.3s;
	line-height:38px;
}

/*label before*/
.input__label-content--kozakura {
	color: #fff;/*color label before*/
	padding: 0.25em 0;
	-webkit-transition: -webkit-transform 0.3s;
	transition: transform 0.3s;
	font-size: 20px;
}

.input__label-content--kozakura::after {
	content: attr(data-content);
	position: absolute;
	font-weight: 800;
	top: 100%;
	left: 0;
	height: 100%;
	width: 100%;
	color: #5e5e5e; /*font-color after anim*/
	padding: 0.25em 0;
	text-transform: uppercase;
	letter-spacing: 1px;
	/*font-size: 0.85em;*/
	font-size: 20px;
}

.graphic--kozakura {
	/*fill: #1586C7;*/
	fill: #5e5e5e; /*bg before anim*/
	pointer-events: none;
	/*top: 1em;*/
	top: 20px;
	bottom: 0px;
	height: 4.5em;
	z-index: -1;
	-webkit-transition: -webkit-transform 0.7s, fill 0.7s;
	transition: transform 0.7s, fill 0.7s;
	-webkit-transition-timing-function: cubic-bezier(0, 0.25, 0.5, 1);
	transition-timing-function: cubic-bezier(0, 0.25, 0.5, 1);
} 

.input__field--kozakura:focus,
.input--filled .input__field--kozakura {
	-webkit-transition: opacity 0s 0.35s;
	transition: opacity 0s 0.35s;
	opacity: 1;
}

.input__field--kozakura:focus + .input__label--kozakura,
.input--filled .input__label--kozakura {
	-webkit-transition-delay: 0.15s;
	transition-delay: 0.15s;
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}

.input__field--kozakura:focus + .input__label--kozakura .input__label-content--kozakura,
.input--filled .input__label-content--kozakura {
	-webkit-transition-delay: 0.15s;
	transition-delay: 0.15s;
	-webkit-transform: translate3d(0, -100%, 0);
	transform: translate3d(0, -100%, 0);
}

.input__field--kozakura:focus ~ .graphic--kozakura,
.input--filled .graphic--kozakura {
	fill: #d3d3d3;/*bg after anim*/
	-webkit-transform: translate3d(-66.6%, 0, 0);
	transform: translate3d(-66.6%, 0, 0);
}

/* Makiko */
.input--makiko {
	overflow: hidden;
	background: #8781BD;
}

.input__field--makiko {
	width: 100%;
	background: transparent;
	color: #797693;
	z-index: 10;
	font-weight: 500;
}

.input__label--makiko {
	position: absolute;
	width: 100%;
	text-align: left;
	pointer-events: none;
	color: #fff;
}

.input__label--makiko::before {
	content: '';
	position: absolute;
	width: 30px;
	height: 30px;
	top: 45%;
	left: 20px;
	background: url(../img/search.svg) no-repeat center center;
	background-size: 100%;
	-webkit-transition: -webkit-transform 0.3s;
	transition: transform 0.3s;
}

.input__label-content--makiko {
	display: block;
	padding: 1.5em 0 0 2.75em;
	-webkit-transition: -webkit-transform 0.3s;
	transition: transform 0.3s;
}

.input__field--makiko:focus + .input__label--makiko::before,
.input--filled .input__label--makiko::before {
	-webkit-transform: scale3d(38, 38, 1);
	transform: scale3d(38, 38, 1);
}

/* Sae */
.input--sae {
	overflow: hidden;
	width: 200px;
	margin: 0 2em 2em;
}

.input__field--sae {
	background: transparent;
	width: 100%;
	color: #fff;
	padding: 1em 0 0.25em;
	font-weight: 500;
}

.input__label--sae {
	position: absolute;
	width: 100%;
	text-align: left;
	color: #7771ab;
	padding: 0;
	height: 100%;
	pointer-events: none;
}

.input__label--sae::after {
	content: '';
	position: absolute;
	width: 100%;
	height: 2px;
	background: #fff;
	bottom: 0;
	-webkit-transform: translate3d(100%, 0, 0);
	transform: translate3d(100%, 0, 0);
	-webkit-transition: -webkit-transform 0.3s;
	transition: transform 0.3s;
}

.icon--sae {
	position: absolute;
	bottom: 0;
	font-size: 1em;
	opacity: 0.5;
	left: -30px;
	color: #fff;
	pointer-events: none;
	-webkit-transform: translate3d(205px, 0, 0);
	transform: translate3d(205px, 0, 0);
	-webkit-transition: -webkit-transform 0.3s;
	transition: transform 0.3s;
}

.input__label-content--sae {
	padding: 0;
	font-size: 1.3em;
	display: block;
	-webkit-transform-origin: 0 0;
	transform-origin: 0 0;
	-webkit-transform: translate3d(0, 1.85em, 0);
	transform: translate3d(0, 1.85em, 0);
	-webkit-transition: -webkit-transform 0.3s;
	transition: transform 0.3s;
}

.input__field--sae:focus + .input__label--sae .input__label-content--sae,
.input--filled .input__label-content--sae {
	-webkit-transform: translate3d(0, 0, 0) scale3d(0.7, 0.7, 1);
	transform: translate3d(0, 0, 0) scale3d(0.7, 0.7, 1);
}

.input__field--sae:focus + .input__label--sae::after,
.input--filled .input__label--sae::after {
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}

.input__field--sae:focus + .input__label--sae .icon--sae,
.input--filled .icon--sae {
	-webkit-transition-delay: 0.01s;
	transition-delay: 0.01s;
	-webkit-transform: translate3d(0, 0, 0) rotate3d(0, 0, 1, -90deg);
	transform: translate3d(0, 0, 0) rotate3d(0, 0, 1, -90deg);
}

/* Nariko */
.input--nariko {
	overflow: hidden;
	padding-top: 2em;
}

.input__field--nariko {
	width: 100%;
	background: transparent;
	opacity: 0;
	padding: 0.35em;
	z-index: 100;
	color: #f18292;
}

.input__label--nariko {
	width: 100%;
	bottom: 0;
	position: absolute;
	pointer-events: none;
	text-align: left;
	color: #8E9191;
	padding: 0 0.5em;
}

.input__label--nariko::before {
	content: '';
	position: absolute;
	width: 100%;
	height: 4em;
	top: 100%;
	left: 0;
	background: #fff;
	border-top: 4px solid #6B6E6E;
	-webkit-transform: translate3d(0, -3px, 0);
	transform: translate3d(0, -3px, 0);
	-webkit-transition: -webkit-transform 0.3s;
	transition: transform 0.3s;
}

.input__label-content--nariko {
	padding: 0.5em 0;
	-webkit-transform-origin: 0% 100%;
	transform-origin: 0% 100%;
	-webkit-transition: -webkit-transform 0.3s, color 0.3s;
	transition: transform 0.3s, color 0.3s;
}

.input__field--nariko:focus,
.input--filled .input__field--nariko {
	cursor: text;
	opacity: 1;
	-webkit-transition: opacity 0s 0.3s;
	transition: opacity 0s 0.3s;
} 

.input__field--nariko:focus + .input__label--nariko::before,
.input--filled .input__label--nariko::before {
	-webkit-transition-delay: 0.1s;
	transition-delay: 0.1s;
	-webkit-transform: translate3d(0, -3.3em, 0);
	transform: translate3d(0, -3.3em, 0);
}

.input__field--nariko:focus + .input__label--nariko .input__label-content--nariko,
.input--filled .input__label-content--nariko {
	color: #6B6E6E;
	-webkit-transform: translate3d(0, -3.3em, 0) scale3d(0.81, 0.81, 1);
	transform: translate3d(0, -3.3em, 0) scale3d(0.81, 0.81, 1);
}

/* Fumi */
.input--fumi {
	background: #fff;
	overflow: hidden;
	padding: 0.25em 0;
}

.input--fumi::after {
	content: '';
	width: 1px;
	position: absolute;
	top: 0.5em;
	bottom: 0.5em;
	left: 2.5em;
	background: #f0f0f0;
	z-index: 100;
}

.input__field--fumi {
	background: transparent;
	padding: 1.5em 1em 0.25em 3.15em;
	width: 100%;
	color: #00aeef;
}

.input__label--fumi {
	position: absolute;
	width: 100%;
	text-align: left;
	padding-left: 4.5em;
	pointer-events: none;
}

.icon--fumi {
	width: 2em;
	position: absolute;
	top: 0;
	left: 0;
	padding: 1em 0 0 0.5em;
}

.input__label-content--fumi {
	padding: 1.7em 0;
	display: inline-block;
	-webkit-transform-origin: 0 0;
	transform-origin: 0 0;
}

.input__label-content--fumi span {
	display: inline-block;
}

.input__field--fumi:focus + .input__label--fumi .input__label-content--fumi,
.input--filled .input__label-content--fumi {
	-webkit-animation: anim-fumi-1 0.3s forwards;
	animation: anim-fumi-1 0.3s forwards;
}

@-webkit-keyframes anim-fumi-1 {
	50% {
		-webkit-transform: translate3d(0, 3em, 0);
		transform: translate3d(0, 3em, 0);
	}
	51% {
		-webkit-transform: translate3d(0, -3em, 0) scale3d(0.85, 0.85, 1);
		transform: translate3d(0, -3em, 0) scale3d(0.85, 0.85, 1);
	}
	100% {
		color: #a3a3a3;
		-webkit-transform: translate3d(0, -1.1em, 0) scale3d(0.85, 0.85, 1);
		transform: translate3d(0, -1.1em, 0) scale3d(0.85, 0.85, 1);
	}
}

@keyframes anim-fumi-1 {
	50% {
		-webkit-transform: translate3d(0, 3em, 0);
		transform: translate3d(0, 3em, 0);
	}
	51% {
		-webkit-transform: translate3d(0, -3em, 0) scale3d(0.85, 0.85, 1);
		transform: translate3d(0, -3em, 0) scale3d(0.85, 0.85, 1);
	}
	100% {
		color: #a3a3a3;
		-webkit-transform: translate3d(0, -1.1em, 0) scale3d(0.85, 0.85, 1);
		transform: translate3d(0, -1.1em, 0) scale3d(0.85, 0.85, 1);
	}
}


.input__field--fumi:focus + .input__label--fumi .icon--fumi,
.input--filled .icon--fumi {
	-webkit-animation: anim-fumi-2 0.3s forwards;
	animation: anim-fumi-2 0.3s forwards;
}

@-webkit-keyframes anim-fumi-2 {
	50% {
		opacity: 1;
		-webkit-transform: translate3d(0, -3em, 0);
		transform: translate3d(0, -3em, 0);
	}
	50.25% {
		opacity: 0;
		-webkit-transform: translate3d(0, -3em, 0);
		transform: translate3d(0, -3em, 0);
	}
	50.75% {
		opacity: 0;
		-webkit-transform: translate3d(0, 3em, 0);
		transform: translate3d(0, 3em, 0);
	}
	51% {
		opacity: 1;
		-webkit-transform: translate3d(0, 3em, 0);
		transform: translate3d(0, 3em, 0);
	}
	100% {
		opacity: 1;
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
		color: #00aeef;
	}
}

@keyframes anim-fumi-2 {
	50% {
		opacity: 1;
		-webkit-transform: translate3d(0, -3em, 0);
		transform: translate3d(0, -3em, 0);
	}
	50.25% {
		opacity: 0;
		-webkit-transform: translate3d(0, -3em, 0);
		transform: translate3d(0, -3em, 0);
	}
	50.75% {
		opacity: 0;
		-webkit-transform: translate3d(0, 3em, 0);
		transform: translate3d(0, 3em, 0);
	}
	51% {
		opacity: 1;
		-webkit-transform: translate3d(0, 3em, 0);
		transform: translate3d(0, 3em, 0);
	}
	100% {
		opacity: 1;
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
		color: #00aeef;
	}
}


/* Ruri */
.input__field--ruri {
	width: 100%;
	background: transparent;
	padding: 0.5em 0;
	margin-bottom: 2em;
	color: #fff;
}

.input__label--ruri {
	width: 100%;
	position: absolute;
	text-align: left;
	font-size: 1em;
	padding: 10px 0 5px;
	pointer-events: none;
}

.input__label--ruri::after {
	content: '';
	position: absolute;
	width: 100%;
	height: 7px;
	background: #6a7989;
	left: 0;
	top: 100%;
	-webkit-transform-origin: 50% 100%;
	transform-origin: 50% 100%;
	-webkit-transition: -webkit-transform 0.3s, background-color 0.3s;
	transition: transform 0.3s, background-color 0.3s;
}

.input__label-content--ruri {
	padding: 0;
	-webkit-transform-origin: 0 0;
	transform-origin: 0 0;
	-webkit-transition: -webkit-transform 0.3s, color 0.3s;
	transition: transform 0.3s, color 0.3s;
}

.input__field--ruri:focus + .input__label--ruri::after,
.input--filled .input__label--ruri::after {
	background: #a3d39c;
	-webkit-transform: scale3d(1, 0.25, 1);
	transform: scale3d(1, 0.25, 1);
}

.input__field--ruri:focus + .input__label--ruri .input__label-content--ruri,
.input--filled .input__label--ruri .input__label-content--ruri {
	color: #a3d39c;
	-webkit-transform: translate3d(0, 2em, 0) scale3d(0.655, 0.655, 1);
	transform: translate3d(0, 2em, 0) scale3d(0.655, 0.655, 1);
}

/* Kohana */
.input--kohana {
	overflow: hidden;
	background: #fff;
}

.input__field--kohana {
	width: 100%;
	background: transparent;
	padding-left: 2.75em;
	color: #6a7989;
}

.input__label--kohana {
	position: absolute;
	width: 100%;
	text-align: left;
	pointer-events: none;
}

.input__label-content--kohana {
	display: inline-block;
	width: auto;
	-webkit-transform: translate3d(-1.75em, 0, 0);
	transform: translate3d(-1.75em, 0, 0);
	-webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
	transition: transform 0.3s, opacity 0.3s;
}

.icon--kohana {
	display: inline-block;
	margin-top: 0.9em;
	-webkit-transform: translate3d(-2em, 0, 0);
	transform: translate3d(-2em, 0, 0);
	-webkit-transition: -webkit-transform 0.3s;
	transition: transform 0.3s;
}

.input__field--kohana:focus + .input__label--kohana .input__label-content--kohana,
.input--filled .input__label-content--kohana {
	opacity: 0;
	-webkit-transform: translate3d(200px, 0, 0);
	transform: translate3d(200px, 0, 0);
}

.input__field--kohana:focus + .input__label--kohana .icon--kohana,
.input--filled  .icon--kohana {
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}

/* Manami */
.input--manami {
	overflow: hidden;
}

.input__field--manami {
	width: 100%;
	background: transparent;
	padding: 0.5em;
	margin-bottom: 2em;
	color: #f48fb1;
	z-index: 100;
	opacity: 0;
}

.input__label--manami {
	width: 100%;
	position: absolute;
	text-align: left;
	padding: 0.5em 0;
	pointer-events: none;
	font-size: 1em;
}

.input__label--manami::before,
.input__label--manami::after {
	content: '';
	position: absolute;
	width: 100%;
	left: 0;
}

.input__label--manami::before {
	height: 100%;
	background: #fff;
	top: 0;
	-webkit-transform: translate3d(0, -100%, 0);
	transform: translate3d(0, -100%, 0);
	-webkit-transition: -webkit-transform 0.3s;
	transition: transform 0.3s;
}

.input__label--manami::after {
	height: 2px;
	background: #6a7989;
	top: 100%;
	-webkit-transition: opacity 0.3s;
	transition: opacity 0.3s;
}

.input__label-content--manami {
	padding: 0;
	-webkit-transform-origin: 0 0;
	transform-origin: 0 0;
	-webkit-transition: -webkit-transform 0.3s, color 0.3s;
	transition: transform 0.3s, color 0.3s;
}

.input__field--manami:focus,
.input--filled .input__field--manami {
	opacity: 1;
	-webkit-transition: opacity 0s 0.3s;
	transition: opacity 0s 0.3s;
}

.input__field--manami:focus + .input__label--manami::before,
.input--filled .input__label--manami::before {
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}

.input__field--manami:focus + .input__label--manami::after,
.input--filled .input__label--manami::after {
	opacity: 0;
}

.input__field--manami:focus + .input__label--manami .input__label-content--manami,
.input--filled .input__label--manami .input__label-content--manami {
	color: #cbc4c6;
	-webkit-transform: translate3d(0, 2.1em, 0) scale3d(0.65, 0.65, 1);
	transform: translate3d(0, 2.1em, 0) scale3d(0.65, 0.65, 1);
}